import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import AlertBox from 'components/Content/AlertBox';

import './about.scss';

import brochurePDF from '../../assets/NS-05830_NS_Patient_Brochure_Digital_CLEAN.pdf';
import imgSpray from '../../assets/images/patient-nascobal-spray.png';
import img46 from '../../assets/images/clinical-data.png';
import aboutHeroMobile from '../../assets/images/vitaminb12-options-hero-mobile.png';

let Subscription = null;

const AboutPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  // const metaData = appConfigs?.metaData?.patient;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NASCOBAL® | What is NASCOBAL®?';
  const pageDescription = 'Learn about NASCOBAL® Nasal Spray';

  const renderPage = () => {
    return (
      <div id="nascobal-patient-about">
        <ContentBlock>
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    WHAT IS <br className="hide-only-mobile" />
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NASCOBAL,
                      }}
                    />{' '}
                    <br className="hide-only-mobile" />
                    NASAL SPRAY?
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={aboutHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white>
              <Row>
                <Col xs={12} md={6}>
                  <p className="sub-title">
                    The first FDA-approved prescription vitamin B
                    <sub className="vitaminb12-sub">12</sub> Nasal Spray
                  </p>
                  <p>
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NASCOBAL,
                      }}
                    />{' '}
                    Nasal Spray is a prescription medicine used as a supplement
                    to treat vitamin B<sub className="vitaminb12-sub">12</sub>{' '}
                    deficiency. It is designed to bypass the gastrointestinal
                    (GI) tract and be absorbed directly into the bloodstream. It
                    is clinically proven to have increased and maintained
                    healthy vitamin B<sub className="vitaminb12-sub">12</sub>{' '}
                    levels.{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NASCOBAL,
                      }}
                    />{' '}
                    is a tasteless and odorless fine mist with a convenient,
                    once-weekly dosing regimen:{' '}
                  </p>
                  <p className="strong">1 spray, 1 nostril, 1x a week.*</p>
                  <Row center="xs">
                    <Col xs={12}>
                      <img
                        src={imgSpray}
                        alt="patient-nascobal-spray"
                        className="product-shot"
                      />
                      <p
                        style={{
                          fontSize: 12,
                          marginTop: -20,
                          marginBottom: 30,
                        }}
                      >
                        Product shown may not be actual size.
                      </p>
                      <AlertBox>
                        Do not drink hot liquids or eat hot foods at least 1
                        hour before and 1 hour after taking{' '}
                        <span
                          className="brandname-nascobal"
                          dangerouslySetInnerHTML={{
                            __html: brandNames.NASCOBAL,
                          }}
                        />{' '}
                        Nasal Spray.
                        <p className="text-blue">
                          <strong>
                            &#42;Dose adjustments may be required.
                          </strong>
                        </p>
                      </AlertBox>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className="flex-display">
                  <ColorBlock gray>
                    <p className="sub-title">
                      Clinically proven to have increased vitamin B
                      <sub className="vitaminb12-sub">12</sub> levels from the
                      first dose
                    </p>
                    <div style={{ textAlign: 'center' }}>
                      <img src={img46} alt="Clinical data" />
                    </div>
                    <p
                      className="footnote dagger top-spacer"
                      style={{ fontSize: 14, marginBottom: 30 }}
                    >
                      In 21 healthy volunteers under fasting conditions, a
                      single 500 mcg dose of{' '}
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.NASCOBAL,
                        }}
                      />{' '}
                      was given and monitored for 3&nbsp;days.
                    </p>
                    <BrandCTA
                      ExternalLink
                      href={brochurePDF}
                      LinkCaption="DOWNLOAD PATIENT BROCHURE"
                    >
                      DOWNLOAD PATIENT BROCHURE
                    </BrandCTA>
                  </ColorBlock>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white>
              <Row>
                <Col xs={12} md={6}>
                  <BrandCTA
                    href="/vitaminb12/how"
                    LinkCaption="SEE HOW TO USE NASCOBAL"
                  >
                    SEE HOW TO USE NASCOBAL
                    <sup className="brand-reg-mark">&reg;</sup>
                  </BrandCTA>
                </Col>
                <Col xs={12} md={6}>
                  <BrandCTA
                    href="https://nascobal-savings-card.azurewebsites.net/savings-card/"
                    LinkCaption="DOWNLOAD COPAY CARD"
                    ExternalLink
                  >
                    DOWNLOAD COPAY CARD
                  </BrandCTA>
                </Col>
              </Row>
            </ColorBlock>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="patient-about">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default AboutPage;
